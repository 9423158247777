.supp-bg-img {
  background-image: url('../../../images/supporters/bg_support.png');
  background-size: 300px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  display: block;
  height: 100%;
  z-index: -1;
}

.supp-bg-img-dark {
  filter: brightness(23%);
  transition: 0.5s;
}

.supp-bg-img-light {
  opacity: 0.15;
  filter: brightness(100%);
  transition: 0.5s;
}

/* ------------------------------------------------------------------------- */

.img-e-quad {
  border-radius: 0px 0px 10px 0px;
  height: 490px;
  width: 45%;
  padding: 0;
  background-position: center;
  background-image: url('../../../images/supporters/e_quad.jpg');
  background-repeat: no-repeat;
  background-size: fill;
  float: right;
  transition: all 0.1s ease;
}

/* ------------------------------------------------------------------------- */

.supp-logo-seas {
  border-radius: 8px 8px 0px 0px;
  height: 100px;
  width: 100%;
  padding: 0;
  margin: 0;
  background-position: center;
  background-image: url('../../../images/supporters/princeton_seas.png');
  background-repeat: no-repeat;
  background-size: min(280px, 75vw);
  float: left;
  clear: both;
  transition: all 0.1s ease;
}

.supp-logo-seas:hover {
  background-size: min(290px, 76vw);
  transition: all 0.1s ease;
}

.supp-logo-seas-dark {
  background-color: rgba(255, 143, 0, 0.7);
}

.supp-logo-seas-light {
  background-color: rgba(0, 112, 255, 0.3);
  filter: invert(1);
}

/* ------------------------------------------------------------------------- */

.supp-logo-nvidia {
  border-radius: 8px 8px 0px 0px;
  height: 100px;
  width: 100%;
  padding: 0;
  margin: 0;
  background-position: center;
  background-image: url('../../../images/supporters/nvidia.png');
  background-repeat: no-repeat;
  background-size: min(230px, 70vw);
  float: left;
  transition: all 0.1s ease;
}

.supp-logo-nvidia:hover {
  background-size: min(240px, 70vw);
  transition: all 0.1s ease;
}

.supp-logo-nvidia-dark {
  background-color: rgba(137, 70, 255, 0.7);
  filter: invert(1);
}

.supp-logo-nvidia-light {
  background-color: rgba(118, 185, 0, 0.3);
}

/* ------------------------------------------------------------------------- */

.supp-logo-hmei {
  border-radius: 8px 8px 0px 0px;
  height: 100px;
  width: 100%;
  padding: 0;
  margin: 0;
  background-position: center;
  background-image: url('../../../images/supporters/high_meadows.png');
  background-repeat: no-repeat;
  background-size: min(230px, 70vw);
  float: left;
  transition: all 0.1s ease;
}

.supp-logo-hmei:hover {
  background-size: min(240px, 70vw);
  transition: all 0.1s ease;
}

.supp-logo-hmei-dark {
  background-color: rgba(71, 142, 118, 0.7);
}

.supp-logo-hmei-light {
  background-color: rgba(184, 113, 137, 0.3);
  filter: invert(1);
}

/* ------------------------------------------------------------------------- */

.supp-logo-keller {
  border-radius: 8px 8px 0px 0px;
  height: 100px;
  width: 100%;
  padding: 0;
  margin: 0;
  background-position: center;
  background-image: url('../../../images/supporters/keller_center.png');
  background-repeat: no-repeat;
  background-size: min(240px, 70vw);
  float: left;
  transition: all 0.1s ease;
}

.supp-logo-keller:hover {
  background-size: min(250px, 72vw);
  transition: all 0.1s ease;
}

.supp-logo-keller-dark {
  background-color: rgba(255, 150, 100, 0.7);
}

.supp-logo-keller-light {
  background-color: rgba(0, 105, 155, 0.3);
  filter: invert(1);
}

/* ------------------------------------------------------------------------- */
.supp-logo-mcmaster {
  border-radius: 8px 8px 0px 0px;
  height: 100px;
  width: 100%;
  padding: 0;
  margin: 0;
  background-position: center;
  background-image: url('../../../images/supporters/McMaster-Carr_logo.svg.png');
  background-repeat: no-repeat;
  background-size: min(240px, 70vw);
  float: left;
  transition: all 0.1s ease;
}

.supp-logo-mcmaster:hover {
  background-size: min(250px, 72vw);
  transition: all 0.1s ease;
}

/* .supp-logo-mcmaster-dark {
  background-color: rgba(70, 71, 71, 0.7);
}

.supp-logo-mcmaster-light {
  background-color: rgba(65, 76, 72, 0.3);
  filter: invert(1);
} */
/* ------------------------------------------------------------------------- */

.supp-logo-mouser {
  border-radius: 8px 8px 0px 0px;
  height: 100px;
  width: 100%;
  padding: 0;
  margin: 0;
  background-position: center;
  background-image: url('../../../images/supporters/MouserLogo.png');
  background-repeat: no-repeat;
  background-size: min(240px, 70vw);
  float: left;
  transition: all 0.1s ease;
}

.supp-logo-mouser:hover {
  background-size: min(250px, 72vw);
  transition: all 0.1s ease;
} 

/* ------------------------------------------------------------------------- */

.supp-logo-cst {
  border-radius: 8px 8px 0px 0px;
  height: 100px;
  width: 100%;
  padding: 0;
  margin: 0;
  background-position: center;
  background-image: url('../../../images/supporters/cst.png');
  background-repeat: no-repeat;
  background-size: min(200px, 60vw);
  float: left;
  transition: all 0.1s ease;
}

.supp-logo-cst:hover {
  background-size: min(210px, 63vw);
  transition: all 0.1s ease;
}

.supp-logo-cst-dark {
  background-color: rgba(255, 100, 100, 0.7);
}

.supp-logo-cst-light {
  background-color: rgba(0, 155, 155, 0.3);
  filter: invert(1);
}

/* ------------------------------------------------------------------------- */

.text-box-keller {
  width: calc(49% - 40px);
  float: left;
}

.text-box-cst {
  width: calc(51%);
  float: right;
}

.supp-info-text {
  height: flex;
  width: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  float: left;
}

.supp-info-text-e-quad {
  width: calc(55% - 60px);
}

/* ------------------------------------------------------------------------- */

.mobile-break {
  display: none;
}

/* ------------------------------------------------------------------------- */

@media screen and (max-width: 1250px) {
  .img-e-quad {
    height: auto;
  }

  .supp-info-text-e-quad {
    width: auto;
  }
}

/* ------------------------------------------------------------------------- */

@media screen and (max-width: 960px) {
  .supp-bg-img {
    max-height: calc(100vh - 200px);
  }

  .text-box-keller {
    width: calc(100%);
  }

  .text-box-cst {
    width: calc(100%);
  }

  .dedent {
    line-height: 25px;
  }
}

/* ------------------------------------------------------------------------- */

@media screen and (max-width: 532px) {
  .mobile-break {
    display: inline-block;
  }
}
